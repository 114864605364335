import { Pipe, PipeTransform } from '@angular/core';
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  isDate,
  isValid,
  isBefore,
  add,
} from 'date-fns';

@Pipe({
  name: 'fromNow',
})
export class FromNowPipe implements PipeTransform {
  public transform(
    value: Date | string | number,
    options: {
      maxParts?: number;
      suffix?: string;
      shortNames?: boolean;
      showSeconds?: boolean;
    } = {
      maxParts: 1,
      suffix: 'from now',
      shortNames: false,
      showSeconds: false,
    },
  ): string {
    if (!value) {
      return '—';
    }
    let asDate = value;
    if (!isDate(value)) {
      asDate = new Date(value);
    }
    return preciseDiffToHuman(
      asDate as Date,
      options.maxParts ?? 1,
      options.suffix ?? 'from now',
      options.shortNames ?? false,
      options.showSeconds ?? false,
    );
  }
}

const differenceInFn = {
  second: differenceInSeconds,
  minute: differenceInMinutes,
  hour: differenceInHours,
  day: differenceInDays,
  month: differenceInMonths,
};
type possibleParts = keyof typeof differenceInFn;

interface PreciseDiff {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  months: number;
}

function preciseDifference(
  date: Date,
  parts: possibleParts[] = ['month', 'day', 'hour', 'minute'],
): PreciseDiff | null {
  let now = new Date();
  if (isBefore(date, now)) {
    return null;
  }
  const response: PreciseDiff = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
  };

  parts.forEach((part, i) => {
    const fn = differenceInFn[part];
    if (fn) {
      const differenceInPart = fn(date, now);
      if (differenceInPart) {
        response[`${part}s`] = differenceInPart;
        const additionDuration = { [`${part}s`]: differenceInPart };
        // We are just going to subtract from "now" until we get down to the date
        now = add(now, additionDuration);
      }
    }
  });
  return response;
}

const dateOrderWithSeconds: (keyof PreciseDiff)[] = ['months', 'days', 'hours', 'minutes', 'seconds'];
const dateOrder: (keyof PreciseDiff)[] = ['months', 'days', 'hours', 'minutes'];

const substitutes: Partial<Record<keyof PreciseDiff, string>> = {
  months: 'mo',
  minutes: 'mins',
  seconds: 'sec',
};

export const preciseDiffToHuman = (
  date: Date,
  maxParts: number,
  suffix: string,
  shortNames = false,
  showSeconds = false,
): string => {
  let response = '—';
  if (!isValid(date)) {
    return response;
  }

  const preciseDiff = showSeconds
    ? preciseDifference(date, ['month', 'day', 'hour', 'minute', 'second'])
    : preciseDifference(date);

  if (!preciseDiff) {
    return response;
  }

  const order = showSeconds ? dateOrderWithSeconds : dateOrder;
  const result = [];
  for (let i = 0; i < order.length && result.length < maxParts; i++) {
    const key = order[i];
    if (preciseDiff[key] > 0) {
      const keyName = substitutes[key] ?? key;
      if (shortNames) {
        result.push(`${preciseDiff[key]}${keyName[0]}`);
      } else {
        result.push(`${preciseDiff[key]} ${removePluralIfNeeded(preciseDiff[key], keyName)}`);
      }
    }
  }

  if (result.length) {
    response = `${result.join(' ')} ${suffix}`;
  }

  return response;
};

function removePluralIfNeeded(count: number, pluralString: string) {
  if (count !== 1 || !pluralString.endsWith('s')) {
    return pluralString;
  }
  return pluralString.substring(0, pluralString.length - 1);
}
