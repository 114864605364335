import { Injectable } from '@angular/core';
import FlatfileImporter from 'flatfile-csv-importer';
import { filter, take } from 'rxjs/operators';
import { lastValueFrom } from 'rxjs';
import { AuthService } from './auth.service';
const LICENSE_KEY = 'fc74579d-5213-413c-ac09-44d65b744b96';

export interface FlatfileField {
  label: string;
  key: string;
  description?: string;
  alternates?: string[];
  validators?: {
    validate:
      | 'regex_matches'
      | 'regex_excludes'
      | 'required'
      | 'required_without'
      | 'unique'
      | 'required_with'
      | 'required_with_all'
      | 'required_without_all'
      | 'required_with_values'
      | 'required_without_values'
      | 'required_with_all_values'
      | 'required_without_all_values';
    error: string;
    regex?: string;
    regexFlags?: { ignoreCase?: boolean; multiline?: boolean; dotAll?: boolean; unicode?: boolean };
    fields?: string[];
  }[];
  type?: 'select' | 'checkbox';
  options?: {
    value: any;
    label: string;
    alternates?: string[];
  }[];
}

@Injectable({
  providedIn: 'root',
})
export class FlatFileService {
  constructor(private initalizationService: AuthService) {
    FlatfileImporter.setVersion(2);
  }

  public async getImporter(fields: FlatfileField[], type: string): Promise<FlatfileImporter> {
    const user = await lastValueFrom(
      this.initalizationService.userInfo$.pipe(
        filter((u) => !!u),
        take(1),
      ),
    );
    return new FlatfileImporter(
      LICENSE_KEY,
      { type, fields, disableManualInput: true, managed: true },
      {
        userId: user.userId,
        name: user.userName,
        companyName: user.BrokerAccountName,
        companyId: user.BrokerAccountId,
      },
    );
  }
}
