import { Pipe, PipeTransform } from '@angular/core';
import { stringOrDateToDate } from '../utilities/date';
import { differenceInHoursWithOptions } from 'date-fns/fp';
import { differenceInHours } from 'date-fns';

@Pipe({
  name: 'inHours',
})
export class InHoursPipe implements PipeTransform {
  public transform(value: string | Date): string {
    if (!value) {
      return '—';
    }
    const date = stringOrDateToDate(value);
    const hours = differenceInHours(date, new Date());
    if (hours < 0) {
      return '—';
    }
    return `${hours.toLocaleString()} hours`;
  }
}
