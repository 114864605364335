import { Component, OnDestroy } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../constants';

// @ts-ignore
@Component({
  template: '',
})
export abstract class NetworkableDestroyableComponent implements OnDestroy {
  protected networkActive$$ = new BehaviorSubject<boolean>(false);
  public networkActive$ = this.networkActive$$.asObservable().pipe(shareReplay(shareReplayComponentConfig));

  protected destroy$$ = new Subject<void>();

  protected destroy() {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  public ngOnDestroy(): void {
    this.destroy();
  }

  public get isActive() {
    return this.networkActive$$.value;
  }

  public set isActive(active: boolean) {
    this.networkActive$$.next(active);
  }
}
