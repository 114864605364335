import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[tbHighlightText]',
})
export class HighlightTextDirective implements OnInit {
  @Input() public highClass = 'text-stickyNoteBrown';
  @Input() public highlightIndex: number[][];

  constructor(private element: ElementRef) {}

  public ngOnInit(): void {
    if (!this.highlightIndex) {
      return;
    }
    let text = this.element.nativeElement.innerText as string;
    const start = `<span class="${this.highClass}">`;
    const end = `</span>`;
    for (const highlightIndex1 of this.highlightIndex) {
      if (highlightIndex1.length !== 2) {
        continue;
      }
      text = [
        text.slice(0, highlightIndex1[0]),
        start,
        text.slice(highlightIndex1[0], highlightIndex1[1] + 1),
        end,
        text.slice(highlightIndex1[1] + 1),
      ].join('');
    }
    this.element.nativeElement.innerHTML = text;
  }
}
