// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const apiKey = 'TB_API_URL';
export const logLevelKey = 'TB_LOG_LEVEL';
export const pollingEnabledKey = 'TB_POLLING_ENABLED';
const defaultApiUrl = 'https://stage.api.lohi.ai';

export const environment = {
  production: false,
  get api(): string {
    return getSessionThenLocalWithDefault(apiKey, defaultApiUrl);
  },
  get intercomId() {
    return 'xrexjig0';
  },
  dispatcherWebURL: 'https://stage.web.5f.app',
};

const getSessionThenLocalWithDefault = (key: string, defaultValue: string): string => {
  const sessionValue = sessionStorage.getItem(key);
  if (sessionValue) {
    return sessionValue;
  }

  const localValue = localStorage.getItem(key);
  if (localValue) {
    return localValue;
  }

  return defaultValue;
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
