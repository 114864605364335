import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { BreadcrumbEntry, BreadcrumbService } from '../services/breadcrumb.service';
import { ActivatedRoute } from '@angular/router';

@Directive({
  selector: '[tbBreadcrumb]',
})
export class BreadcrumbDirective implements OnDestroy {
  @Input() public set tbBreadcrumb(breadcrumbs: BreadcrumbEntry[]) {
    this.breadcrumbService.setBreadcrumbs(breadcrumbs);
  }

  constructor(private breadcrumbService: BreadcrumbService) {}

  public ngOnDestroy(): void {
    this.breadcrumbService.setBreadcrumbs([]);
  }
}
