import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'zeroIfNothing',
})
export class ZeroIfNothingPipe implements PipeTransform {
  public transform(value: any): number {
    if (!value) {
      return 0;
    }
    return value;
  }
}
