import { Injectable } from '@angular/core';
import { interval } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VersioningService {
  constructor(private swUpdate: SwUpdate) {
    if (window.location.hostname === 'localhost') {
      return;
    }
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate();
    }

    this.swUpdate.versionUpdates
      .pipe(
        filter((v) => v.type === 'VERSION_READY'),
        take(1),
      )
      .subscribe((event) => {
        document.location.reload();
      });
  }
}
