import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'rfc3339DateOnly',
})
export class RFC3339DateOnlyPipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return '';
    }
    if (value.length >= 10) {
      return value.substring(0, 10);
    }
    return value;
  }
}
