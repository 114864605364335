<div class="w-full flex flex-col" style="max-height: 90vh">
  <div class="flex gap-2 overflow-x-auto">
    <div
      *ngFor="let image of images; trackBy: images | trackByKey : null"
      class="w-12"
      (click)="setSelectedImage(image)"
    >
      <div [matTooltip]="image.name" class="truncate" [class.font-bold]="image === (selectedImage$ | async)">
        {{ image.name }}
      </div>
      <img [src]="image.src" class="object-cover" [alt]="image.name" />
    </div>
  </div>
  <div *ngIf="selectedImage$ | async as selectedImage" style="height: calc(90vh - 150px)" class="my-2">
    <img [src]="selectedImage.src" [alt]="selectedImage.name" class="object-contain w-full h-full" />
  </div>
</div>
