import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DestroyableComponent } from '../destroyable.component';
import { catchError, debounceTime, lastValueFrom, Observable, of, takeUntil } from 'rxjs';
import { HereLookupResult, HereSearchResult, HereService } from '../../services/here.service';
import { shareReplay, switchMap } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../../constants';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'tb-address-search-v2',
  templateUrl: './address-search-v2.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSearchV2Component),
    },
  ],
})
export class AddressSearchV2Component extends DestroyableComponent implements ControlValueAccessor, OnChanges {
  @HostBinding('class.contents') public contents = true;

  @Input() public outputType: 'xy' | 'longlat' = 'longlat';
  @Input() public label = 'Address Search';
  @Input() public initialSearch: string = null;

  @Output() public addressResult = new EventEmitter<HereSearchResult>();
  public addressSearch = new FormControl<string>(null, []);
  public addresses$: Observable<HereSearchResult[]>;

  private onChange: Function;
  public onTouched: Function;

  constructor(private here: HereService) {
    super();

    this.addresses$ = this.addressSearch.valueChanges.pipe(
      debounceTime(300),
      switchMap((v) => {
        if (!v || v?.length < 3) {
          return of([] as HereSearchResult[]);
        }
        return this.here.searchForAddresses$(v).pipe(catchError(() => of([])));
      }),
      shareReplay(shareReplayComponentConfig),
    );
    // start searching right away
    this.addresses$.pipe(takeUntil(this.destroy$$)).subscribe({});
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.addressSearch.disable();
    } else {
      this.addressSearch.enable();
    }
  }

  public async lookupAndWrite(selected: MatAutocompleteSelectedEvent) {
    const lookup = await lastValueFrom(this.here.lookup$(selected.option.value.id));
    this.writeValue(lookup);
  }

  public writeValue(obj: HereLookupResult): void {
    if (obj) {
      this.addressSearch.setValue(obj?.address?.label || this.initialSearch);
      if (this.onChange && obj.position) {
        this.onChange(
          this.outputType === 'xy'
            ? {
                x: obj.position.lng,
                y: obj.position.lat,
                valid: true,
              }
            : {
                longitude: obj.position.lng,
                latitude: obj.position.lat,
              },
        );
      }
      this.addressResult.next(obj);
    } else {
      if (this.onChange) {
        this.onChange(null);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (this.initialSearch && this.addressSearch.pristine) {
      this.addressSearch.setValue(this.initialSearch);
    }
  }
}
