import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { LoadService } from '../../../modules/loads/load.service';
import { FormBuilder, Validators } from '@angular/forms';
import { NetworkableDestroyableComponent } from '../networkable-destroyable.component';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { stringOrDateToDateISO } from '../../utilities/date';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tb-shipment-export',
  templateUrl: './shipment-export.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentExportComponent extends NetworkableDestroyableComponent {
  public form = this.fb.group({
    startAt: this.fb.control(startOfDay(sub(new Date(), { months: 1 })), [Validators.required]),
    endAt: this.fb.control(endOfDay(new Date()), [Validators.required]),
  });
  constructor(
    private loadService: LoadService,
    private fb: FormBuilder,
    private ref: MatDialogRef<ShipmentExportComponent>,
  ) {
    super();
  }

  public async getExport() {
    if (this.isActive) {
      return;
    }
    try {
      this.isActive = true;
      const { startAt, endAt } = this.form.value;
      const exportURL = await this.loadService.getShipmentExport(
        stringOrDateToDateISO(startAt),
        stringOrDateToDateISO(endAt),
      );
      if (exportURL) {
        window.open(exportURL, '_blank');
        this.ref.close();
      }
    } finally {
      this.isActive = false;
    }
  }
}
