import { Pipe, PipeTransform } from '@angular/core';
import { ShipmentType } from '../../modules/internal/edi/shared/model/enums';

/* eslint-disable @typescript-eslint/naming-convention */
const shipmentTypes: Record<string, ShipmentType> = {
  '368': ShipmentType.stockTransfer,
  '369': ShipmentType.issue,
  '377': ShipmentType.collection,
  '378': ShipmentType.collection,
};
/* eslint-enable @typescript-eslint/naming-convention */

@Pipe({
  name: 'shipmentType',
})
export class ShipmentTypePipe implements PipeTransform {
  public transform(value: string): ShipmentType {
    if (!value) {
      return null;
    }

    return shipmentTypes[value?.substring(0, 3)];
  }
}
