import { Pipe, PipeTransform } from '@angular/core';
import { differenceInMinutes } from 'date-fns';
import { stringOrDateToDate } from '../utilities/date';

@Pipe({
  name: 'differenceInMinutes',
})
export class DifferenceInMinutesPipe implements PipeTransform {
  public transform(value1: string | Date, value2: string | Date = new Date()): number {
    if (!value1 || !value2) {
      return 0;
    }
    const baseDate = stringOrDateToDate(value1);
    const dateToCompareIsAfter = stringOrDateToDate(value2);
    return differenceInMinutes(baseDate, dateToCompareIsAfter);
  }
}
