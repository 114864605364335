import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { shareReplayComponentConfig } from '../../constants';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'tb-double-confirm',
  templateUrl: './double-confirm.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DoubleConfirmComponent {
  @Input() public confirmText: string = 'Confirm';
  @Input() public buttonColor: ThemePalette = 'accent';
  @Input() public buttonType: 'button' | 'flat' | 'stroked' | 'raised' = 'button';
  @Input() public disabled: boolean = false;

  @Output() public confirm = new EventEmitter<boolean>();

  private clicked$$ = new BehaviorSubject(false);
  public clicked$: Observable<boolean> = this.clicked$$.pipe(shareReplay(shareReplayComponentConfig));

  constructor() {}

  @HostListener('mouseleave')
  public unclick() {
    this.clicked$$.next(false);
  }

  @HostListener('click')
  public click() {
    if (this.disabled) {
      return;
    }
    if (this.clicked$$.value) {
      return;
    }
    this.clicked$$.next(true);
  }
}
