import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { AuthService, BrokerUserInfo } from '../services/auth.service';
import { filter, map } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { IsInternalService } from '../services/is-internal.service';

@Injectable({
  providedIn: 'root',
})
export class InternalGuard implements CanActivate, CanLoad {
  constructor(private internalService: IsInternalService, private router: Router) {}
  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.internalService.isInternal$.pipe(
      map((isInternal) => {
        if (isInternal) {
          return true;
        }
        return this.router.parseUrl('/');
      }),
    );
  }

  public canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.internalService.isInternal$.pipe(
      map((isInternal) => {
        if (isInternal) {
          return true;
        }
        return this.router.parseUrl('/');
      }),
    );
  }
}
