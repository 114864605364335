import { Pipe, PipeTransform } from '@angular/core';
import { LoadTypesEnum } from '../../modules/internal/dispatch-pool/model/enums';

@Pipe({
  name: 'loadType',
})
export class LoadTypePipe implements PipeTransform {
  public transform(value: LoadTypesEnum): string[] {
    if (value === null || value === undefined) {
      return null;
    }

    const loadTypes = [];
    if (value === LoadTypesEnum.none) {
      loadTypes.push('None');
    }
    if (value === LoadTypesEnum.live) {
      loadTypes.push('Live');
    }
    if (value === LoadTypesEnum.drop) {
      loadTypes.push('Drop');
    }
    if (value === LoadTypesEnum.liveDrop) {
      loadTypes.push('Live');
      loadTypes.push('Drop');
    }

    return loadTypes;
  }
}
