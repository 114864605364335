import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'map',
})
export class MapPipe implements PipeTransform {
  public transform<V>(value: V[], property: keyof V): any[] {
    if (!Array.isArray(value)) {
      return [];
    }
    return value.map((value1) => value1[property]);
  }
}
