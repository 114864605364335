import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { LoadSearchService } from './load-search.service';

@Component({
  selector: 'tb-load-search',
  templateUrl: './load-search.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadSearchComponent {
  private _searchTerm: string = null;
  public searchResults$ = this.search.searchResults$;
  public loading$ = this.search.loading$;

  public get searchTerm(): string {
    return this._searchTerm;
  }

  public set searchTerm(term: string) {
    this._searchTerm = term;
    this.search.search(this._searchTerm);
  }

  constructor(private search: LoadSearchService, private matDialog: MatDialog, private router: Router) {}

  public close() {
    this.searchTerm = '';
    this.search.close();
  }

  public async goToFirstEntry() {
    const results = await lastValueFrom(this.searchResults$.pipe(take(1)));
    if (results.length) {
      const first = results[0];
      await this.router.navigate(['/', 'loads', 'load', first.internalId]);
      this.close();
    }
  }
}
