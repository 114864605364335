import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { shareReplay, switchMap, throttleTime } from 'rxjs/operators';

export class SimpleFinn<T> {
  private networkActive$$ = new BehaviorSubject<boolean>(false);
  public networkActive$: Observable<boolean> = this.networkActive$$.pipe(shareReplay(1));
  private bSubject$$: BehaviorSubject<T>;
  private throttle$$ = new Subject<void>();
  private readonly shared$: Observable<T>;
  private readonly loadFunc: () => Promise<T>;

  constructor(initValue: T = null, loadFunc: () => Promise<T>) {
    this.bSubject$$ = new BehaviorSubject<T>(initValue);
    this.shared$ = this.bSubject$$.pipe(shareReplay(1));
    this.loadFunc = loadFunc;
    this.throttle$$
      .pipe(
        throttleTime(1000),
        switchMap(() => {
          this.networkActive$$.next(true);
          return from(this.loadFunc());
        }),
      )
      .subscribe({
        next: (value) => {
          this.bSubject$$.next(value);
          this.networkActive$$.next(false);
        },
        error: (err) => {
          this.networkActive$$.next(false);
        },
      });
  }

  public get$(): Observable<T> {
    this.throttle$$.next();
    return this.shared$;
  }

  public next(value: T) {
    this.bSubject$$.next(value);
  }
}
