import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { interval } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'tb-time-ticker',
  templateUrl: './time-ticker.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeTickerComponent {
  @Input() public until: Date | string | number;
  @Input() public since: Date | string | number;
  @Input() public maxParts = 2;

  protected readonly timeTicker$ = interval(1000).pipe(
    startWith(1),
    map((s) => new Date(this.until || this.since).getTime() + (s % 2)),
  );
}
