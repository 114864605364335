import { ChangeDetectionStrategy, Component, HostListener, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, combineLatestWith, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { GlobalSearchResult } from '../../../../shared/types';
import { shareReplayComponentConfig } from '../../../../shared/constants';
import { VstLoadsService } from '../vst-loads.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'tb-load-search',
  templateUrl: './load-search.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadSearchComponent {
  public searchBox = new FormControl<string>(null);
  public searchResults$: Observable<GlobalSearchResult[]>;

  private maxLoadsToShow$$ = new BehaviorSubject<number>(5);
  public maxLoadsToShow$ = this.maxLoadsToShow$$.pipe(shareReplay(shareReplayComponentConfig));
  public maxLoadSearchResults$: Observable<{
    results: GlobalSearchResult[];
    showMore: boolean;
  }>;
  public active$: Observable<boolean>;
  public firstResult$: Observable<GlobalSearchResult>;

  constructor(
    private loadService: VstLoadsService,
    public ref: MatDialogRef<LoadSearchComponent>,
    private router: Router,
  ) {
    this.searchResults$ = this.loadService.searchResults$;
    this.active$ = this.loadService.loading$;
    this.searchBox.valueChanges.subscribe({
      next: (value) => this.loadService.search(value),
    });

    this.maxLoadSearchResults$ = this.searchResults$.pipe(
      combineLatestWith(this.maxLoadsToShow$),
      map(([results, max]) => {
        return {
          results: results.slice(0, max),
          showMore: results.length > max,
        };
      }),
    );

    this.firstResult$ = this.searchResults$.pipe(
      map((results) => results[0]),
      shareReplay(shareReplayComponentConfig),
    );
  }

  public showMoreLoads() {
    this.maxLoadsToShow$$.next(this.maxLoadsToShow$$.value + 5);
  }

  @HostListener('document:keydown', ['$event'])
  public async onKeyDown(event: KeyboardEvent) {
    if (event.key.toLowerCase() === 'tab') {
      return;
    }

    if ((event.target as HTMLElement).tagName === 'INPUT') {
      if (event.key.toLowerCase() === 'enter') {
        this.handleEnter();
        return;
      }
      return;
    }

    if (event.key.toLowerCase() === 'enter') {
      if (this.searchBox.pristine) {
        this.handleEnter();
        return;
      }
      return;
    }
  }

  private async handleEnter() {
    const firstResult = await lastValueFrom(this.firstResult$.pipe(take(1)));
    if (firstResult) {
      this.ref.close();
      this.router.navigate(['/vst/loads', firstResult.id]);
    }
  }

  public async openAll() {
    const loads = await firstValueFrom(this.searchResults$);
    for (const load of loads) {
      window.open(`${window.location.origin}/vst/loads/${load.id}`, '_blank');
    }
  }
}
