import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { distinctUntilChanged, filter, map, shareReplay, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../../environments/environment';
import { SnackBarService } from './snackbar.service';

export type CustomerType = 'shipper' | 'broker' | 'agent';

export interface BrokerUserInfo {
  internal: boolean;
  userId: string;
  userName: string;
  userEmail: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BrokerAccountId: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  BrokerAccountName: string;
  apiDailyRateLimit: number;
  type: CustomerType;
  enableRebateProgram: boolean;
  permissions: string[];
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  // @ts-ignore
  private userInfo$$ = new BehaviorSubject<BrokerUserInfo>(null);
  public userInfo$ = this.userInfo$$.asObservable().pipe(shareReplay(1));
  private httpClientNoInterceptors: HttpClient;
  // @ts-ignore
  private isLoggedIn$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  public isLoggedIn$ = this.isLoggedIn$$.asObservable().pipe(
    filter((isLoggedIn) => isLoggedIn !== null),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public isInternal$ = this.userInfo$.pipe(
    filter((userInfo) => !!userInfo),
    map((userInfo) => userInfo.internal),
  );

  constructor(
    httpBackend: HttpBackend,
    private logger: NGXLogger,
    private afAuth: AngularFireAuth,
    private snackbar: SnackBarService,
  ) {
    this.httpClientNoInterceptors = new HttpClient(httpBackend);

    this.afAuth.authState.subscribe(async (user) => {
      if (user) {
        await this.loadUserInfo();
        const userInfo = await lastValueFrom(this.userInfo$.pipe(take(1)));
        if (!userInfo) {
          this.isLoggedIn$$.next(false);
          this.logout();
          return;
        }
        this.isLoggedIn$$.next(true);
      } else {
        this.isLoggedIn$$.next(false);
      }
    });
  }

  public async loginWithPhone(phoneNumber: string, password: string): Promise<boolean> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.post<{ customToken: string }>(
          `${environment.api}/v1/login/password`,
          { phoneNumber, password },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
              'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
            },
          },
        ),
      );
      await this.afAuth.signInWithCustomToken(result.customToken);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }

  public async logout(redirect = true) {
    await this.afAuth.signOut();
    if (redirect) {
      window.location.pathname = '/';
    }
  }

  public async loadUserInfo() {
    const token = await lastValueFrom(
      this.afAuth.idToken.pipe(
        filter((t) => !!t),
        take(1),
      ),
    );
    try {
      const userInfo = await lastValueFrom(
        this.httpClientNoInterceptors.get<BrokerUserInfo>(`${environment.api}/v1/external/broker_portal/user_info`, {
          headers: {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            Authorization: `Bearer ${token}`,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            'Content-Type': 'application/json',
            'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
            'x-api-web-app': 'broker',
          },
        }),
      );
      this.userInfo$$.next(userInfo);
    } catch (e) {
      this.snackbar.showError('Invalid User');
      await this.afAuth.signOut();
    }
  }

  public async doPasswordReset(resetCode: string, newPassword: string): Promise<string> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.post<{ message: string }>(
          `${environment.api}/v1/do_password_reset`,
          {
            resetCode,
            newPassword,
          },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
            },
          },
        ),
      );
      return result.message;
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  }

  public async getPasswordResetCode(phoneNumber: string): Promise<string> {
    try {
      const result = await lastValueFrom(
        await this.httpClientNoInterceptors.post<{ message: string }>(
          `${environment.api}/v1/password_reset_code`,
          {
            phoneNumber,
          },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
            },
          },
        ),
      );
      return result.message;
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  }

  public async getPasswordResetCodeEmail(email: string): Promise<string> {
    try {
      const result = await lastValueFrom(
        await this.httpClientNoInterceptors.post<{ message: string }>(
          `${environment.api}/v1/password_reset_code_email`,
          {
            email,
          },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
            },
          },
        ),
      );
      return result.message;
    } catch (error) {
      this.logger.error(error);
      return null;
    }
  }

  public async loginWithLoHiToken(token: string): Promise<boolean> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.get<{ customToken: string }>(`${environment.api}/v1/login/lohi_token`, {
          headers: {
            'Content-Type': 'application/json',
            'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
          },
          params: {
            token,
          },
        }),
      );
      await this.afAuth.signInWithCustomToken(result.customToken);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }

  public async loginWithCustomToken(token: string): Promise<boolean> {
    try {
      await this.afAuth.signInWithCustomToken(token);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }

  public async loginWithEmail(email: string, password: string): Promise<boolean> {
    try {
      const result = await lastValueFrom(
        this.httpClientNoInterceptors.post<{ customToken: string }>(
          `${environment.api}/v1/login/password_email`,
          { email, password },
          {
            headers: {
              // eslint-disable-next-line @typescript-eslint/naming-convention
              'Content-Type': 'application/json',
              'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
            },
          },
        ),
      );
      await this.afAuth.signInWithCustomToken(result.customToken);
      return true;
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  }
}
