import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tb-fade',
  templateUrl: './fade.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/*
  This component is used to fade in content.
  It will fade in when the component is initialized and to the show input if provided.
 */
export class FadeComponent implements AfterViewInit, OnChanges {
  @Input() public show = true;

  private readonly show$$ = new BehaviorSubject<boolean>(false);
  public show$ = this.show$$.asObservable();

  public ngAfterViewInit() {
    if (!this.show === false) {
      this.show$$.next(true);
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.show$$.next(this.show);
  }
}
