export enum StatPeriod {
  total = 'Total',
  today = 'Today',
  last7Days = 'Last 7 Days',
  last30Days = 'Last 30 Days',
}

export enum ShipmentType {
  issue = 'Issue',
  collection = 'Collection',
  stockTransfer = 'Stock Transfer',
}

export enum ShipmentStatusUpdate {
  currentLocation = 'X6',
  arrivedAtPickup = 'X3',
  departedFromPickup = 'AF',
  etaAtDropoff = 'AG',
  arrivedAtDropoff = 'X1',
  completedUnloadingAtDropoff = 'D1',
  departedFromDropoff = 'CD',
}

export enum TenderStatus {
  active = 'active',
  accepted = 'accepted',
  declined = 'declined',
  canceled = 'canceled',
  expired = 'expired',
  archived = 'archived',
}
