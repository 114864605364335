import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, debounceTime, Observable, of, Subject, switchMap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LohiLoadFromLoadboardStatus } from '../loads/types';
import { take } from 'rxjs/operators';
import { LoadSearchComponent } from './load-search.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

export interface SearchResult {
  status: LohiLoadFromLoadboardStatus;
  internalId: string;
  referenceNumber: string;
  carrierName: string;
  containerNumber: string;
  bookingNumber: string;
  specialHandling: string;
}

@Injectable({
  providedIn: 'root',
})
export class LoadSearchService {
  private searchText$$ = new Subject<string>();
  private loading$$ = new BehaviorSubject<boolean>(false);
  private searchResults$$ = new BehaviorSubject<SearchResult[]>([]);
  private dialogRef: MatDialogRef<any>;

  public get searchResults$(): Observable<SearchResult[]> {
    return this.searchResults$$;
  }

  public get loading$(): Observable<boolean> {
    return this.loading$$;
  }

  constructor(private http: HttpClient, private matDialog: MatDialog) {
    this.listenToSearchTextChange();
  }

  public search(text: string) {
    this.searchText$$.next(text);
  }

  public open() {
    if (this.dialogRef) {
      return;
    }

    this.dialogRef = this.matDialog.open(LoadSearchComponent);
    this.dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => (this.dialogRef = null));
  }

  public close() {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  private listenToSearchTextChange() {
    this.searchText$$
      .pipe(debounceTime(50))
      .pipe(
        switchMap((searchTextRaw) => {
          this.loading$$.next(true);
          const searchText = searchTextRaw?.trim();
          const identifiers = [searchText];
          if (identifiers?.length && searchText.length) {
            return this.http.get<{ loads: SearchResult[] }>(
              `${environment.api}/v1/external/broker_portal/loads/search`,
              { params: { identifiers } },
            );
          } else {
            return of({ loads: new Array<SearchResult>() });
          }
        }),
      )
      .subscribe((results) => {
        this.loading$$.next(false);
        this.searchResults$$.next(results.loads);
      });
  }
}
