import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { AngularFireAuthGuard, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { InternalGuard } from './shared/guards/internal.guard';
import { InternalOrExternalDispatcherGuard } from './shared/guards/internal-or-external-dispatcher.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['auth']);

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('src/app/modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'loads',
    loadChildren: () => import('src/app/modules/loads/loads.module').then((m) => m.LoadsModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'api_keys',
    loadChildren: () => import('src/app/modules/api-keys/api-keys.module').then((m) => m.ApiKeysModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'users',
    loadChildren: () => import('src/app/modules/users/users.module').then((m) => m.UsersModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'rebate',
    loadChildren: () => import('src/app/modules/rebate/rebate.module').then((m) => m.RebateModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'facilities',
    loadChildren: () => import('src/app/modules/facilities/facilities.module').then((m) => m.FacilitiesModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'metrics',
    loadChildren: () => import('src/app/modules/metrics/metrics.module').then((m) => m.MetricsModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'vmi',
    loadChildren: () => import('src/app/modules/vmi/vmi.module').then((m) => m.VmiModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'linked_loads',
    loadChildren: () => import('src/app/modules/backhaul/backhaul.module').then((m) => m.BackhaulModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'shipment_planner',
    loadChildren: () =>
      import('src/app/modules/shipment-planner/shipment-planner.module').then((m) => m.ShipmentPlannerModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'planner_dashboard',
    loadChildren: () =>
      import('src/app/modules/planner-dashboard/planner-dashboard.module').then((m) => m.PlannerDashboardModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'auto_booking_dashboard',
    loadChildren: () =>
      import('src/app/modules/auto-booking-dashboard/auto-booking-dashboard.module').then(
        (m) => m.AutoBookingDashboardModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'shipment_planner_warehouse_delayed_pickup',
    loadChildren: () =>
      import('src/app/modules/shipment-planner/warehouse-delayed-pickup/warehouse-delayed-pickup.module').then(
        (m) => m.WarehouseDelayedPickupModule,
      ),
  },
  {
    path: 'shipper_network_analysis',
    loadChildren: () =>
      import('src/app/modules/shipper-network-analysis/shipper-network-analysis.module').then(
        (m) => m.ShipperNetworkAnalysisModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'internal',
    loadChildren: () => import('src/app/modules/internal/internal.module').then((m) => m.InternalModule),
    canActivate: [AngularFireAuthGuard, InternalOrExternalDispatcherGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'autofulfillment',
    loadChildren: () =>
      import('src/app/modules/autofulfillment/autofulfillment.module').then((m) => m.AutofulfillmentModule),
    canActivate: [AngularFireAuthGuard, InternalGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'carrier_management',
    loadChildren: () =>
      import('src/app/modules/carrier-management/carrier-management.module').then((m) => m.CarrierManagementModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'autodispatch_pools',
    loadChildren: () =>
      import('src/app/modules/autodispatch-pool/autodispatch-pool.module').then((m) => m.AutodispatchPoolModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'performance_dashboard',
    loadChildren: () =>
      import('src/app/modules/performance-dashboard/performance-dashboard.module').then(
        (m) => m.PerformanceDashboardModule,
      ),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'freight_pool',
    loadChildren: () =>
      import('src/app/modules/freight-pooling/freight-pooling.module').then((m) => m.FreightPoolingModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'billing',
    loadChildren: () => import('src/app/modules/billing/billing.module').then((m) => m.BillingModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'document_importer',
    loadChildren: () =>
      import('src/app/modules/document-importer/document-importer.module').then((m) => m.DocumentImporterModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'recommendations',
    loadChildren: () =>
      import('src/app/modules/recommendations/recommendations.module').then((m) => m.RecommendationsModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'autoorder',
    loadChildren: () => import('src/app/modules/autoorder/autoorder.module').then((m) => m.AutoorderModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'network_map',
    loadChildren: () => import('src/app/modules/network-map/network-map.module').then((m) => m.NetworkMapModule),
    canActivate: [AngularFireAuthGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },
  {
    path: 'vst',
    children: [
      {
        path: 'home',
        loadChildren: () => import('src/app/modules/vst/home/home.module').then((m) => m.HomeModule),
        canActivate: [AngularFireAuthGuard],
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
      {
        path: 'facility',
        loadChildren: () =>
          import('src/app/modules/vst/vst-facilities/vst-facilities.module').then((m) => m.VstFacilitiesModule),
        canActivate: [AngularFireAuthGuard],
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
      {
        path: 'loads',
        loadChildren: () => import('src/app/modules/vst/vst-loads/vst-loads.module').then((m) => m.VstLoadsModule),
        canActivate: [AngularFireAuthGuard],
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
      {
        path: 'scheduling',
        loadChildren: () =>
          import('src/app/modules/vst/appointment-request/appointment-request.module').then(
            (m) => m.AppointmentRequestModule,
          ),
        canActivate: [AngularFireAuthGuard],
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
      {
        path: 'asset_dashboard',
        loadChildren: () =>
          import('src/app/modules/vst/asset-dashboard/asset-dashboard.module').then((m) => m.AssetDashboardModule),
        canActivate: [AngularFireAuthGuard],
        data: {
          authGuardPipe: redirectUnauthorizedToLogin,
        },
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'home',
      },
    ],
  },
  {
    path: 'ldtkn',
    loadChildren: () =>
      import('src/app/modules/no-auth-tokenized-load-details/no-auth-tokenized-load-details.module').then(
        (m) => m.NoAuthTokenizedLoadDetailsModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'auth',
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'auth',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
