import { Pipe, PipeTransform } from '@angular/core';

const trailers: Record<string, string> = {
  reefer: 'Reefer',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  dry_van: 'Dry Van',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  power_only: 'Power Only',
  flatbed: 'Flatbed',
  hopper: 'Hopper',
  pneumatic: 'Pneumatic',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  belly_dump: 'Belly Dump',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  end_dump: 'End Dump',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  straight_box: 'Straight Box',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  intermodal_chassis: 'Intermodal Chassis',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  tanker: 'Tanker',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  stepdeck: 'Step Deck',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  low_boy: 'Low Boy',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  conestoga: 'Conestoga',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '40_foot_high_cube_container': "40' HC",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '20_foot': "20'",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  '40_foot': "40'",
  // eslint-disable-next-line @typescript-eslint/naming-convention
  dry_van_48_foot: 'Dry Van - 48 Foot',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  dry_van_53_foot_costco: 'Dry Van (Costco)',
};

@Pipe({
  name: 'trailerType',
})
export class TrailerTypePipe implements PipeTransform {
  public transform<T extends string | string[]>(value: T): T {
    if (!value) {
      return value;
    }

    if (Array.isArray(value)) {
      return value.map((v) => trailers[v]) as T;
    }
    if (typeof value === 'string') {
      return trailers[value] as T;
    }

    return value;
  }
}
