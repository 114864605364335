<div class="w-[450px] h-[90vh] flex-col flex-initial">
  <div>
    <mat-form-field class="w-full">
      <input
        matInput
        placeholder="Search"
        type="text"
        autocomplete="off"
        [(ngModel)]="searchTerm"
        (keydown.enter)="goToFirstEntry()"
      />
      <mat-spinner diameter="24" matSuffix *ngIf="loading$ | async"></mat-spinner>
    </mat-form-field>
  </div>
  <div class="flex-1 overflow-auto">
    <div
      *ngFor="let load of searchResults$ | async; trackBy: searchResults$ | async | trackByKey : 'internalId'"
      class="p-2 border-solid border-b last:border-b-0"
    >
      <a [routerLink]="['/', 'loads', 'load', load.internalId]" (click)="close()">
        <div>5F ID: {{ load.internalId }}</div>
        <div>Load ID: {{ load.referenceNumber }}</div>
        <div>Status: {{ load.status | lohiLoadStatus }}</div>
        <div>Carrier: {{ load.carrierName | dashIfNothing }}</div>
        <div *ngIf="load.containerNumber">Container Number: {{ load.containerNumber | dashIfNothing }}</div>
        <div *ngIf="load.bookingNumber">Booking Number: {{ load.bookingNumber | dashIfNothing }}</div>
        <div *ngIf="load.specialHandling">Type: {{ load.specialHandling | titlecase }}</div>
      </a>
    </div>
    <div *ngIf="!searchTerm">
      You can search shipments by load number, container number, booking number, or our load ID (e.g. LLLBQB1NZCQ)
    </div>
    <div *ngIf="searchTerm && (loading$ | async) === false && !(searchResults$ | async)?.length">
      No shipments matched your search
    </div>
  </div>
</div>
