import { Injectable } from '@angular/core';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export interface BreadcrumbEntry {
  label: string;
  url: null | string | string[];
}

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private breadcrumbs$$ = new BehaviorSubject<BreadcrumbEntry[]>([]);
  public breadcrumbs$ = this.breadcrumbs$$.asObservable().pipe(debounceTime(100), shareReplay(1));

  constructor() {}

  public setBreadcrumbs(breadcrumbs: BreadcrumbEntry[]) {
    this.breadcrumbs$$.next(breadcrumbs);
  }
}
