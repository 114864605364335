import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';

export interface TimezoneResponse {
  name: string;
  abbreviation: string;
}

@Injectable({
  providedIn: 'root',
})
export class TimezoneService {
  constructor(private http: HttpClient) {}

  public queryTimezoneForLocation$(latitude: number, longitude: number): Observable<TimezoneResponse> {
    return this.http.get<TimezoneResponse>(`${environment.api}/v2/max_payloop/timezone`, {
      params: {
        latitude,
        longitude,
      },
    });
  }
}
