import { Directive, HostListener, Input } from '@angular/core';
import { RecordCallService } from '../services/record-call.service';
import { environment } from '../../../environments/environment';
import { NGXLogger } from 'ngx-logger';

@Directive({
  selector: '[tbRecordCall]',
})
export class RecordCallDirective {
  @Input() public driverId: string;

  @HostListener('click', ['$event']) public onClick($event) {
    this.recordCall();
  }

  constructor(private recordCallService: RecordCallService, private logger: NGXLogger) {}

  private async recordCall() {
    if (!this.driverId) {
      if (!environment.production) {
        this.logger.debug('driver id not provided for call directive');
      }
      return;
    }
    const success = await this.recordCallService.recordCall(this.driverId);
    if (success) {
      this.logger.debug('called recorded');
    }
  }
}
