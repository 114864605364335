import { Pipe, PipeTransform } from '@angular/core';

export const isImage = /(gif|jpe?g|tiff?|png|webp|bmp|svg)$/;
@Pipe({
  name: 'isImage',
})
export class IsImagePipe implements PipeTransform {
  public transform(value: string): boolean {
    try {
      return isImage.test(value) || isImage.test(new URL(value).pathname);
    } catch {
      return false;
    }
  }
}
