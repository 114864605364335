import { Pipe, PipeTransform } from '@angular/core';
import {
  differenceInSeconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInMonths,
  sub,
  isDate,
  isAfter,
  isValid,
} from 'date-fns';

@Pipe({
  name: 'ago',
})
export class AgoPipe implements PipeTransform {
  public transform(
    value: Date | string | number,
    options: {
      maxParts?: number;
      suffix?: string;
      shortNames?: boolean;
      showSeconds?: boolean;
    } = {
      maxParts: 1,
      suffix: 'ago',
      shortNames: false,
      showSeconds: false,
    },
  ): string {
    if (!value) {
      return '—';
    }
    let asDate = value;
    if (!isDate(value)) {
      asDate = new Date(value);
    }
    return preciseDiffToHuman(
      asDate as Date,
      options.maxParts ?? 1,
      options.suffix ?? 'ago',
      options.shortNames ?? false,
      options.showSeconds ?? false,
    );
  }
}

const differenceInFn = {
  second: differenceInSeconds,
  minute: differenceInMinutes,
  hour: differenceInHours,
  day: differenceInDays,
  month: differenceInMonths,
};
type PossibleParts = keyof typeof differenceInFn;

interface PreciseDiff {
  seconds: number;
  minutes: number;
  hours: number;
  days: number;
  months: number;
}

const preciseDifference = (
  date: Date,
  parts: PossibleParts[] = ['month', 'day', 'hour', 'minute'],
): PreciseDiff | null => {
  let now = new Date();
  if (isAfter(date, now)) {
    return null;
  }
  const response: PreciseDiff = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    months: 0,
  };

  for (const part of parts) {
    const fn = differenceInFn[part];
    if (fn) {
      const differenceInPart = fn(now, date);
      if (differenceInPart) {
        response[`${part}s`] = differenceInPart;
        const subtractionDuration = { [`${part}s`]: differenceInPart };
        // We are just going to subtract from "now" until we get down to the date
        now = sub(now, subtractionDuration);
      }
    }
  }

  return response;
};

const dateOrderWIthSeconds: (keyof PreciseDiff)[] = ['months', 'days', 'hours', 'minutes', 'seconds'];
const dateOrder: (keyof PreciseDiff)[] = ['months', 'days', 'hours', 'minutes'];

const substitutes: Partial<Record<keyof PreciseDiff, string>> = {
  months: 'mo',
  minutes: 'm',
  hours: 'h',
  days: 'd',
  seconds: 's',
};

const preciseDiffToHuman = (
  date: Date,
  maxParts: number,
  suffix: string,
  useShort: boolean,
  showSeconds = false,
): string => {
  let response = '—';
  if (!isValid(date)) {
    return response;
  }
  const preciseDiff = showSeconds
    ? preciseDifference(date, ['month', 'day', 'hour', 'minute', 'second'])
    : preciseDifference(date);

  if (!preciseDiff) {
    return response;
  }

  const order = showSeconds ? dateOrderWIthSeconds : dateOrder;
  const result = [];
  for (let i = 0; i < order.length && result.length < maxParts; i++) {
    const key = order[i];
    if (preciseDiff[key] <= 0) continue;
    const keyName = substitutes[key] && useShort ? substitutes[key] : key;
    useShort
      ? result.push(`${preciseDiff[key]}${removePluralIfNeeded(preciseDiff[key], keyName)}`)
      : result.push(`${preciseDiff[key]} ${removePluralIfNeeded(preciseDiff[key], keyName)}`);
  }

  return result.length ? `${result.join(' ')} ${suffix}` : 'just now';
};

const removePluralIfNeeded = (count: number, pluralString: string) => {
  if (count !== 1 || !pluralString.endsWith('s')) {
    return pluralString;
  }
  return pluralString.substring(0, pluralString.length - 1);
};
