import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService, BrokerUserInfo, CustomerType } from '../services/auth.service';
import { BehaviorSubject, combineLatest, Observable, Subject, takeUntil } from 'rxjs';

@Directive({
  selector: '[tbAccountType]',
})
export class AccountTypeDirective implements OnDestroy {
  private hasView = false;
  private destroy$$ = new Subject<void>();
  public users$: Observable<BrokerUserInfo>;
  private accountTypes$$ = new BehaviorSubject<CustomerType[]>([]);
  private notAccountTypes$$ = new BehaviorSubject<CustomerType[]>([]);

  @Input() public set tbAccountType(accountFeatures: CustomerType | CustomerType[]) {
    if (!Array.isArray(accountFeatures)) {
      this.accountTypes$$.next([accountFeatures]);
    } else {
      this.accountTypes$$.next(accountFeatures);
    }
  }

  @Input() public set tbAccountTypeNot(accountFeatures: CustomerType | CustomerType[]) {
    if (!Array.isArray(accountFeatures)) {
      this.notAccountTypes$$.next([accountFeatures]);
    } else {
      this.notAccountTypes$$.next(accountFeatures);
    }
  }

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cd: ChangeDetectorRef,
  ) {
    this.users$ = this.authService.userInfo$;
    this.listenForViewUpdates();
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
    this.destroy$$.unsubscribe();
  }

  private listenForViewUpdates() {
    combineLatest([this.authService.userInfo$, this.accountTypes$$, this.notAccountTypes$$])
      .pipe(takeUntil(this.destroy$$))
      .subscribe(([userProfile, accountTypes, notAccountTypes]) => {
        // I think this is fine, users can only have one account type so just double checking if they cen see it
        if (
          userProfile &&
          accountTypes.length &&
          accountTypes.includes(userProfile.type) &&
          !(notAccountTypes.length && notAccountTypes.includes(userProfile.type))
        ) {
          this.renderView();
        } else {
          this.clearView();
        }
      });
  }

  private renderView() {
    if (!this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
      this.cd.markForCheck();
    }
  }

  private clearView() {
    if (this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
      this.cd.markForCheck();
    }
  }
}
