<ng-container *ngIf="clicked$ | async; else notClicked">
  <ng-container [ngSwitch]="buttonType">
    <ng-container *ngSwitchCase="'button'">
      <button mat-button [color]="buttonColor" (click)="confirm.emit(true)">
        {{ confirmText }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'raised'">
      <button mat-raised-button [color]="buttonColor" (click)="confirm.emit(true)">
        {{ confirmText }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'stroked'">
      <button mat-stroked-button [color]="buttonColor" (click)="confirm.emit(true)">
        {{ confirmText }}
      </button>
    </ng-container>
    <ng-container *ngSwitchCase="'flat'">
      <button mat-flat-button [color]="buttonColor" (click)="confirm.emit(true)">
        {{ confirmText }}
      </button>
    </ng-container>
  </ng-container>
</ng-container>
<ng-template #notClicked><ng-content></ng-content></ng-template>
