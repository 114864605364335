import { CurrencyPipe } from '@angular/common';
import { Directive, ElementRef, forwardRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject, fromEvent, Observable } from 'rxjs';
import { pluck, startWith, tap } from 'rxjs/operators';

const onlyNumbersAndDecimal = /[^0-9.]/g;
const onlyNumbers = /[^0-9]/g;

@Directive({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberInputDirective),
    },
    CurrencyPipe,
  ],
  selector: '[tbNumberInput]',
})
export class NumberInputDirective implements ControlValueAccessor, OnInit {
  @Input() public noDecimals = false;
  private text$$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public type: string;
  public onChange: (arg0: any) => void;
  public onTouched: (arg0: any) => void;
  public value: number;

  public get text$(): Observable<string> {
    return this.text$$.asObservable();
  }

  constructor(private renderer: Renderer2, private elementRef: ElementRef) {
    this.type = (elementRef.nativeElement as HTMLInputElement).type;
    if (this.type !== 'text') {
      console.warn('number directive text input');
    }
  }

  public ngOnInit() {
    fromEvent(this.elementRef.nativeElement, 'input')
      .pipe(
        tap((event: Event) => {
          event.preventDefault();
          event.stopImmediatePropagation();
          event.stopPropagation();
        }),
        pluck('target', 'value'),
        startWith(''),
      )
      .subscribe((value) => {
        if (this.noDecimals) {
          const inputOnlyNumber = (value as string).replace(onlyNumbers, '');
          this.writeValue(parseFloat(inputOnlyNumber));
        } else {
          const inputOnlyNumberString = (value as string).replace(onlyNumbersAndDecimal, '');
          const inputOnlyNumber = inputOnlyNumberString.endsWith('.')
            ? inputOnlyNumberString
            : parseFloat(inputOnlyNumberString) || 0;
          this.writeValue(inputOnlyNumber);
        }
      });
  }

  public writeValue(input: number | string): void {
    if (typeof input === 'string') {
      if (this.type === 'text') {
        const parsedWithoutDecimal = parseInt(input.substring(0, input.length - 1), 10);
        const formattedField = `${parsedWithoutDecimal.toLocaleString()}.`;
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', formattedField);
        this.text$$.next(formattedField);
      } else {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', input);
        this.text$$.next(`${input}`);
      }
    } else if (!isNaN(input) && input !== null) {
      if (this.type === 'text') {
        const formattedField = input.toLocaleString('lookup', {
          maximumFractionDigits: 20,
        });
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', formattedField);
        this.text$$.next(formattedField);
      } else {
        this.renderer.setProperty(this.elementRef.nativeElement, 'value', input);
        this.text$$.next(`${input}`);
      }
    } else {
      this.text$$.next(null);
      this.renderer.setProperty(this.elementRef.nativeElement, 'value', null);
    }
    if (this.onChange) {
      if (typeof input === 'string') {
        this.onChange(null);
        this.onTouched(null);
      } else {
        this.onChange(input);
        this.onTouched(input);
      }
    }
  }
  public registerOnChange(fn: (arg0: any) => void): void {
    this.onChange = fn;
  }
  public registerOnTouched(fn: (arg0: any) => void): void {
    this.onTouched = fn;
  }
  public setDisabledState?(isDisabled: boolean): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', isDisabled);
  }
}
