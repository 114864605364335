import { AfterViewInit, Directive, ElementRef } from '@angular/core';

// workaround for https://issues.chromium.org/issues/335553723
// remove when Chrome issue is fixed
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-label',
})
export class RemoveAriaOwnsDirective implements AfterViewInit {
  constructor(private el: ElementRef) {}

  public ngAfterViewInit() {
    this.el.nativeElement.parentElement.removeAttribute('aria-owns');
  }
}
