import { ChangeDetectorRef, Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { BehaviorSubject, combineLatest, Subscription } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { FeatureFlagService } from '../services/feature-flags.service';

@Directive({
  selector: '[tbFeatureFlag]',
})
export class FeatureFlagDirective implements OnDestroy {
  private flagName: string;
  private elseTemplate$$ = new BehaviorSubject<TemplateRef<any>>(null);
  private hasView = false;
  private hasElseView = false;
  private subscription: Subscription;

  @Input() public set tbFeatureFlagElse(elseTemplate: TemplateRef<any>) {
    this.elseTemplate$$.next(elseTemplate);
  }

  @Input() public set tbFeatureFlag(flag: string) {
    this.flagName = flag;
    this.updateView();
  }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
    private cd: ChangeDetectorRef,
  ) {}

  public ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateView() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
    this.subscription = combineLatest([
      this.featureFlagService.isFlagActive$(this.flagName).pipe(distinctUntilChanged()),
      this.elseTemplate$$,
    ]).subscribe(([flagActive, elseTemplate]) => {
      if (!flagActive) {
        if (this.hasView) {
          this.viewContainer.clear();
          this.hasView = false;
          this.cd.markForCheck();
        }
        if (elseTemplate && !this.hasElseView) {
          this.viewContainer.createEmbeddedView(elseTemplate);
          this.hasElseView = true;
          this.cd.markForCheck();
        }
        return;
      }

      if (flagActive) {
        if (!this.hasView) {
          if (this.hasElseView) {
            this.viewContainer.clear();
            this.hasElseView = false;
          }
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
          this.cd.markForCheck();
        }
      }
    });
  }
}
