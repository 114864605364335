import { Pipe, PipeTransform } from '@angular/core';

const values: Record<string, string> = {
  container: 'Container',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  container_and_chassis: 'Container and Chassis',
  // eslint-disable-next-line @typescript-eslint/naming-convention
  dry_van: 'Dry Van',
};

@Pipe({
  name: 'assetType',
})
export class AssetPipe implements PipeTransform {
  public transform(value: string): string {
    return values[value] || value;
  }
}
