import { Pipe, PipeTransform } from '@angular/core';

/* eslint-disable @typescript-eslint/naming-convention */
const shipmentStatusUpdates: Record<string, string> = {
  X6: 'Current location',
  X3: 'Arrived at pickup',
  AF: 'Departed from pickup',
  AG: 'ETA at dropoff',
  X1: 'Arrived at dropoff',
  D1: 'Completed unloading at dropoff',
  CD: 'Departed from dropoff',
};
/* eslint-enable @typescript-eslint/naming-convention */

@Pipe({
  name: 'shipmentStatusUpdate',
})
export class ShipmentStatusUpdatePipe implements PipeTransform {
  public transform(value: string): string {
    if (!value) {
      return null;
    }

    return shipmentStatusUpdates[value];
  }
}
