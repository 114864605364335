import { Pipe, PipeTransform } from '@angular/core';
import { TimezoneService } from '../services/timezone.service';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { XYPoint } from '../types';

@Pipe({
  name: 'timezoneName',
})
export class TimezoneNamePipe implements PipeTransform {
  constructor(private tzService: TimezoneService) {}

  public transform(value: XYPoint): Observable<string> {
    if (!value) {
      return of(null);
    }
    return this.tzService.queryTimezoneForLocation$((value as XYPoint).y, (value as XYPoint).x).pipe(
      map((v) => v.name),
      catchError(() => of(null)),
    );
  }
}
