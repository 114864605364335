import { DatePipe, formatDate } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { add, isDate, isToday, isTomorrow } from 'date-fns';

export const standardDateFormat = (value: any): string => {
  const asDate = new Date(value);
  let formattingText = `M/dd/yyyy`;
  if (isToday(asDate)) {
    formattingText = `'Today'`;
  } else if (isTomorrow(asDate)) {
    formattingText = `'Tomorrow'`;
  }
  return formattingText;
};

@Pipe({
  name: 'standardDateOnly',
})
export class StandardDateOnlyPipe extends DatePipe implements PipeTransform {
  // @ts-ignore
  public transform(value: any, format?: string, timezone?: string, locale?: string): string | null {
    if (!value) {
      return '—';
    }
    if (typeof value === 'string' && !value.includes('T')) {
      value = value + 'T00:00:00';
    }

    return super.transform(value, format ?? standardDateFormat(value));
  }
}
