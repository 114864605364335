import { Directive, HostListener, Input } from '@angular/core';
import { NgxMatDatepickerInput } from '@angular-material-components/datetime-picker';

@Directive({
  selector: '[tbDatePicker]',
})
export class DatePickerDirective {
  constructor(private datePicker: NgxMatDatepickerInput<Date>) {}

  @HostListener('click', ['$event'])
  public onClick(event: Event) {
    event.stopPropagation();
    this.datePicker._datepicker.open();
  }
}
