import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { animate, sequence, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'tb-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fadeIn', [
      state('in', style({ opacity: 1, display: 'block' })),
      state('out', style({ opacity: 0, display: 'none' })),
      transition('in => out', [
        sequence([
          style({ opacity: 1 }),
          animate('0.15s ease-in-out', style({ opacity: 0 })),
          style({ opacity: 0, display: 'none' }),
        ]),
      ]),
      transition('out => in', [
        sequence([
          style({ display: 'block', position: 'absolute' }),
          animate('0.15s ease-in-out', style({ position: 'relative' })),
          animate('0.15s ease-in-out', style({ opacity: 1, display: 'block' })),
          style({ opacity: 1 }),
        ]),
      ]),
    ]),
  ],
})
export class BreadcrumbComponent {
  public breadcrumbs$ = this.breadcrumbService.breadcrumbs$;
  constructor(public breadcrumbService: BreadcrumbService) {}
}
