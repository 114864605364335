import { isBefore, isDate, isValid } from 'date-fns';
import { IDataHookRecord } from 'flatfile-csv-importer/build/main/obj.validation-response';
import { Scalar } from 'flatfile-csv-importer';
import * as chrono from 'chrono-node';

const arbitraryDateWeThinkNothingWillHappenBefore = new Date(2010, 1, 1);

export const fieldToDate = (values: [Scalar, number][], meta: any): [IDataHookRecord, number][] =>
  values.map(([value, row]) => {
    try {
      if (!value) {
        return [{ value: null }, row];
      }
      const asNumber = +value;
      if (!isNaN(asNumber)) {
        let valueClone = asNumber;
        let date = new Date(valueClone);
        while (isBefore(date, arbitraryDateWeThinkNothingWillHappenBefore)) {
          valueClone = valueClone * 1000;
          date = new Date(valueClone);
        }
        return [{ value: date.toISOString() }, row];
      }
      if (typeof value === 'string') {
        const date = new Date(value);
        if (!isValid(date)) {
          return [{ value, info: [{ message: 'invalid date', level: 'error' }] }, row];
        }
        return [{ value: date.toISOString() }, row];
      }
      if (!isDate(value)) {
        return [{ value, info: [{ message: 'invalid date', level: 'error' }] }, row];
      }
      return [{ value }, row];
    } catch (e) {
      console.error('value', value, 'row', row, e);
      return [{ value, info: [{ message: 'invalid date', level: 'error' }] }, row];
    }
  });

export const fieldToDateV2 = (values: [Scalar, number][], meta: any): [IDataHookRecord, number][] =>
  values.map(([value, row]) => {
    try {
      if (!value) {
        return [{ value: null }, row];
      }
      const asNumber = +value;
      if (!isNaN(asNumber)) {
        let valueClone = asNumber;
        let date = new Date(valueClone);
        while (isBefore(date, arbitraryDateWeThinkNothingWillHappenBefore)) {
          valueClone = valueClone * 1000;
          date = new Date(valueClone);
        }
        return [{ value: date.toISOString() }, row];
      }
      if (typeof value === 'string') {
        const parsed = chrono.parseDate(value, new Date());
        if (!parsed || !isValid(parsed)) {
          return [{ value, info: [{ message: 'invalid date', level: 'error' }] }, row];
        }
        return [{ value: parsed.toISOString() }, row];
      }
      if (!isDate(value)) {
        return [{ value, info: [{ message: 'invalid date', level: 'error' }] }, row];
      }
      return [{ value }, row];
    } catch (e) {
      console.error(e);
      return [{ value, info: [{ message: 'invalid date', level: 'error' }] }, row];
    }
  });

const onlyNumbers = /\D/g;

export const fieldToNumber = (values: [Scalar, number][], meta: any): [IDataHookRecord, number][] =>
  values.map(([value, row]) => {
    if (!value) {
      return [{ value: 0 }, row];
    }
    const withoutSymbols = +`${value}`.replace(onlyNumbers, '');
    return [{ value: withoutSymbols }, row];
  });

export const fieldToNumberWithDecimals = (values: [Scalar, number][], meta: any): [IDataHookRecord, number][] =>
  values.map(([value, row]) => {
    if (!value) {
      return [{ value }, row];
    }
    if (typeof value === 'number' || typeof value === 'boolean') {
      return [{ value }, row];
    }
    return [{ value: parseFloat(value) }, row];
  });

export const fieldToBoolean = (values: [Scalar, number][], meta: any): [IDataHookRecord, number][] =>
  values.map(([value, row]) => {
    if (!value) {
      return [{ value: false }, row];
    }
    let parsed = false;
    try {
      parsed = JSON.parse(`${value}`.toLowerCase());
    } catch (error) {
      // do something?
    }
    return [{ value: parsed }, row];
  });
