import { Injectable } from '@angular/core';
import { Observable, pluck } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

export interface HereSearchResult {
  title: string;
  id: string;
  resultType: string;
  localityType: string;
  address: Address;
}

export interface HereLookupResult {
  title: string;
  id: string;
  resultType: string;
  localityType: string;
  address: Address;
  position: Position;
  mapView: MapView;
}

export interface Address {
  label: string;
  countryCode: string;
  countryName: string;
  stateCode: string;
  state: string;
  county: string;
  city: string;
  postalCode: string;
  addressCity: string;
  building: string;
  countyCode: string;
  district: string;
  houseNumber: string;
  street: string;
  subblock: string;
  subdistrict: string;
}

export interface MapView {
  west: number;
  south: number;
  east: number;
  north: number;
}

export interface Position {
  lat: number;
  lng: number;
}

export const addressOneLiner = (address: Address): string => {
  if (!address) {
    return '';
  }
  let addressLine1: string = '';
  if (address.houseNumber) {
    addressLine1 = address.houseNumber;
  }
  if (address.street) {
    if (addressLine1) {
      addressLine1 += ' ';
    }
    addressLine1 += address.street;
  }
  let addressLine2: string = '';
  if (address.city) {
    addressLine2 = address.city;
  }
  if (address.stateCode) {
    if (addressLine2) {
      addressLine2 += ', ';
    }
    addressLine2 += address.stateCode;
  }
  if (address.postalCode) {
    if (addressLine2) {
      addressLine2 += ' ';
    }
    addressLine2 += address.postalCode;
  }
  let addressOneLine: string = '';
  if (addressLine1) {
    addressOneLine += addressLine1;
  }
  if (addressLine2) {
    if (addressOneLine) {
      addressOneLine += ', ';
    }
    addressOneLine += addressLine2;
  }
  return addressOneLine;
};

@Injectable({
  providedIn: 'root',
})
export class HereService {
  private httpClient: HttpClient;
  constructor(private http: HttpBackend) {
    this.httpClient = new HttpClient(this.http);
  }

  public searchForAddresses$(search: string): Observable<HereSearchResult[]> {
    return this.httpClient
      .get<{ items: HereSearchResult[] }>(`${environment.api}/v2/external/shipper_load_bid/autosuggest?q=${search}`)
      .pipe(map((v) => v?.items || []));
  }

  public lookup$(id: string): Observable<HereLookupResult> {
    return this.httpClient
      .get<{ result: HereLookupResult }>(`${environment.api}/v2/external/shipper_load_bid/lookup?hereID=${id}`)
      .pipe(map((v) => v?.result));
  }
}
