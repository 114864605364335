import { combineLatest, Observable, Subscribable } from 'rxjs';
import Fuse from 'fuse.js';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { fuse } from './fuse';
import { shareReplayComponentConfig } from '../constants';
import { uniq } from 'remeda';
import FuseOptionKey = Fuse.FuseOptionKey;

export class FusedObservable<T> {
  private readonly fusedInternal$: Observable<T[]>;

  public get fused$(): Observable<T[]> {
    return this.fusedInternal$;
  }

  public constructor(
    toFuse$: Observable<T[]>,
    searchObservable$: Observable<string | null>,
    options: Fuse.IFuseOptions<T> | FuseOptionKey<T>[],
  ) {
    if (Array.isArray(options)) {
      options = {
        distance: 1000,
        keys: options,
        location: 0,
        minMatchCharLength: 1,
        threshold: 0.2,
      } as Fuse.IFuseOptions<T>;
    }
    this.fusedInternal$ = combineLatest([toFuse$.pipe(map(fuse(options))), searchObservable$.pipe(startWith(''))]).pipe(
      map(([data, search]) => {
        if (!search) {
          return data.data;
        }
        const searchTerms = search.split(',').map((s) => s.trim());
        const rawResults: T[] = [];
        searchTerms.forEach((searchTerm) => {
          rawResults.push(...data.fuse.search(searchTerm).map((v) => v.item));
        });
        return uniq(rawResults);
      }),
      shareReplay(shareReplayComponentConfig),
    );
  }
}
