import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { isToday, isTomorrow } from 'date-fns';

@Pipe({
  name: 'standardDate',
})
export class StandardDatePipe extends DatePipe implements PipeTransform {
  // @ts-ignore
  public transform(
    value: any,
    format?: string,
    timezone?: string,
    locale?: string,
    dateOnly = false,
    dateOnlyFormat?: string,
    defaultTo = '—',
  ): string | null {
    if (!value) {
      return defaultTo;
    }
    // This is a nullable time from the server
    if (value.constructor === Object && value.hasOwnProperty('valid') && value.hasOwnProperty('time')) {
      if (!value.valid || !value.time) {
        return defaultTo;
      }
      value = value.time;
    }

    // Date pipe doesn't handle timezones like America/Chicago, just UTC offsets
    if (timezone?.includes('/')) {
      value = new Date(value).toLocaleString('en-US', { timeZone: timezone });
    }

    if (format) {
      return super.transform(value, format, timezone, locale);
    }

    const stdFormat = dateOnly ? standardDateOnlyFormat(value, dateOnlyFormat) : standardDateFormat(value);
    return super.transform(value, stdFormat, timezone, locale);
  }
}

export const standardDateFormat = (value: any): string => {
  const asDate = new Date(value);
  let formattingText = `h:mmaaaaa'm' M/dd/yyyy`;
  if (isToday(asDate)) {
    formattingText = `h:mmaaaaa'm' 'Today'`;
  } else if (isTomorrow(asDate)) {
    formattingText = `h:mmaaaaa'm' 'Tomorrow'`;
  }
  return formattingText;
};

export const standardDateOnlyFormat = (value: any, dateOnlyFormat?: string): string => {
  const asDate = new Date(value);
  let formattingText = dateOnlyFormat ? dateOnlyFormat : 'M/dd/yyyy';
  if (isToday(asDate)) {
    formattingText = `'Today'`;
  } else if (isTomorrow(asDate)) {
    formattingText = `'Tomorrow'`;
  }
  return formattingText;
};
