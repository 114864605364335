import { Pipe, PipeTransform } from '@angular/core';
import { isPast } from 'date-fns';
import { stringOrDateToDate } from '../utilities/date';

@Pipe({
  name: 'isPast',
})
export class IsPastPipe implements PipeTransform {
  public transform(value: string | Date): boolean {
    if (!value) {
      return false;
    }
    return isPast(stringOrDateToDate(value));
  }
}
