import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { catchError, lastValueFrom, Observable, of } from 'rxjs';
import { SimpleFinn } from '../utilities/simpleFinn';

@Injectable({
  providedIn: 'root',
})
export class IsInternalService {
  private isInternalShared: SimpleFinn<boolean>;

  public get isInternalShared$(): Observable<boolean> {
    return this.isInternalShared.get$();
  }

  constructor(private httpClient: HttpClient) {
    this.isInternalShared = new SimpleFinn<boolean>(false, this.loadIsInternal);
  }

  // this should not be used in components, only for guards and services
  // use isInternalShared instead
  public get isInternal$(): Observable<boolean> {
    return this.getIsInternal$();
  }

  private loadIsInternal = async (): Promise<boolean> => await lastValueFrom(this.getIsInternal$());

  private getIsInternal$ = (): Observable<boolean> =>
    this.httpClient.get<{ internal: boolean }>(`${environment.api}/v1/internal/is_internal`).pipe(
      map((response) => {
        return response.internal;
      }),
      catchError(() => {
        // dont need to log the error here, interceptor should handle it
        return of(false);
      }),
    );
}
