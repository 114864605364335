import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'joinWithAnd',
})
export class JoinWithAndPipe implements PipeTransform {
  public transform<T>(value: T[], propertyName?: keyof T, separator?: string, useAnd = true): string {
    if (!Array.isArray(value)) {
      return value;
    }

    if (value.length === 0) {
      return '';
    }

    if (propertyName) {
      return joinWithAnd(
        value.map((value) => value[propertyName]),
        separator,
        useAnd,
      );
    }

    return joinWithAnd(value, separator, useAnd);
  }
}

export const joinWithAnd = (arr: any[], separator?: string, useAnd = true) => {
  if (arr.length === 1) {
    return arr[0];
  }
  if (!useAnd) {
    return arr.join(separator || ', ');
  }
  const firsts = arr.slice(0, arr.length - 1);
  const last = arr[arr.length - 1];
  return `${firsts.join(separator || ', ')} and ${last}`;
};
