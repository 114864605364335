<div mat-dialog-title>Download Shipment Export</div>
<form [formGroup]="form" (ngSubmit)="getExport()">
  <div mat-dialog-content>
    <mat-form-field class="w-full">
      <mat-label>Start And End</mat-label>
      <mat-date-range-input [rangePicker]="picker" required>
        <input matStartDate required formControlName="startAt" placeholder="Start" autocomplete="off" />
        <input matEndDate required formControlName="endAt" placeholder="End" autocomplete="off" />
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <div>
      <button mat-button mat-dialog-close>Cancel</button>
    </div>
    <div>
      <button mat-button type="submit" color="primary" [disabled]="form.invalid">Download</button>
    </div>
  </div>
</form>
