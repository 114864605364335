import { Injectable } from '@angular/core';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { RouterStateService } from './router-state.service';
import { lastValueFrom } from 'rxjs';
import { environment } from '../../../environments/environment';

declare const Intercom: any;

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  private intercomSetup = false;

  private hasIntercom() {
    return hasIntercom() && this.intercomSetup;
  }

  constructor(private routerState: RouterStateService, private authService: AuthService) {
    this.listenForLoggedIn();
    this.listenToRouterStateChanges();
  }

  public show() {
    if (this.hasIntercom()) {
      Intercom('show');
    }
  }

  public showMessages() {
    if (this.hasIntercom()) {
      Intercom('showMessages');
    }
  }

  public showNewMessage() {
    if (this.hasIntercom()) {
      Intercom('showNewMessage');
    }
  }

  private async boot() {
    const user = await lastValueFrom(
      this.authService.userInfo$.pipe(
        filter((u) => !!u && !!u.userId),
        take(1),
      ),
    );
    if (user && user.userId && hasIntercom() && environment.intercomId) {
      const intercomSettings = {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        app_id: environment.intercomId,
        name: user.userName,
        user_id: user.userId,
        email: user.userEmail,
      };
      Intercom('boot', intercomSettings);
      this.intercomSetup = true;
    }
  }

  private shutdown() {
    if (hasIntercom()) {
      Intercom('shutdown');
    }
  }

  private listenToRouterStateChanges() {
    this.routerState.routerState$
      .pipe(
        map((state) => state.url),
        distinctUntilChanged(),
      )
      .subscribe(() => {
        if (hasIntercom()) {
          Intercom('update');
        }
      });
  }

  private listenForLoggedIn() {
    this.authService.isLoggedIn$.pipe(distinctUntilChanged()).subscribe((isLoggedIn) => {
      if (isLoggedIn) {
        this.boot();
      } else {
        this.shutdown();
      }
    });
  }
}

function hasIntercom() {
  return typeof Intercom !== 'undefined';
}
