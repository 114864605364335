import { Pipe, PipeTransform } from '@angular/core';
import {
  add,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInSeconds,
  differenceInWeeks,
  isBefore,
  isValid,
} from 'date-fns';
import { removePluralIfNeeded } from '../utilities/date';

@Pipe({
  name: 'minutesToHoursAndMinutes',
})
export class MinutesToHoursAndMinutesPipe implements PipeTransform {
  public transform(minutes: number, parts: PossibleParts[] = ['hour', 'minute']): string {
    return minutesToHuman(minutes, parts);
  }
}

const minutesToHuman = (minutes: number, parts: PossibleParts[]): string => {
  if (typeof minutes === 'string') {
    return minutes;
  }

  if (minutes === 0) {
    return '0m';
  }

  const date = add(new Date(), { minutes });

  return preciseDiffToHuman(date, 2, '', parts);
};

const differenceInFn = {
  second: differenceInSeconds,
  minute: differenceInMinutes,
  hour: differenceInHours,
  day: differenceInDays,
  weeks: differenceInWeeks,
  month: differenceInMonths,
};

type PossibleParts = keyof typeof differenceInFn;

interface PreciseDiff {
  minutes: number;
  seconds: number;
  hours: number;
  days: number;
  weeks: number;
  months: number;
}

const preciseDifference = (date: Date, parts: PossibleParts[]): PreciseDiff | null => {
  let now = new Date();
  if (isBefore(date, now)) {
    return null;
  }
  const response: PreciseDiff = {
    seconds: 0,
    minutes: 0,
    hours: 0,
    days: 0,
    weeks: 0,
    months: 0,
  };

  parts.forEach((part, i) => {
    const fn = differenceInFn[part];
    if (fn) {
      const differenceInPart = fn(date, now);
      if (differenceInPart) {
        response[`${part}s`] = differenceInPart;
        const additionDuration = { [`${part}s`]: differenceInPart };
        // We are just going to add from "now" until we get up to the date
        now = add(now, additionDuration);
      }
    }
  });
  return response;
};

const dateOrder: (keyof PreciseDiff)[] = ['months', 'weeks', 'days', 'hours', 'minutes'];

const substitutes: Partial<Record<keyof PreciseDiff, string>> = {
  months: 'mo',
  minutes: 'm',
  hours: 'h',
  days: 'd',
};

const preciseDiffToHuman = (date: Date, maxParts: number, suffix: string, parts: PossibleParts[]): string => {
  let response = '—';
  if (isValid(date)) {
    const preciseDiff = preciseDifference(date, parts);
    if (preciseDiff) {
      const result = [];
      let dateOrderIndex = 0;
      while (dateOrderIndex < dateOrder.length && result.length < maxParts) {
        const key = dateOrder[dateOrderIndex];
        if (preciseDiff[key] > 0) {
          const keyName = substitutes[key] ? substitutes[key] : key;
          result.push(`${preciseDiff[key]}${removePluralIfNeeded(preciseDiff[key] as number, keyName)}`);
        }
        dateOrderIndex++;
      }
      if (result.length) {
        response = `${result.join(' ')} ${suffix}`;
      }
    }
  }
  return response;
};
