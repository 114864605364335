import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkInventoryThreshold',
})
export class CheckInventoryThresholdPipe implements PipeTransform {
  public transform(value: { targetZeroStock: boolean; stock: number; warningThreshold: number | null }): boolean {
    if (!value.warningThreshold) {
      return false;
    }
    return value.targetZeroStock ? value.stock >= value.warningThreshold : value.stock <= value.warningThreshold;
  }
}
