import { ChangeDetectionStrategy, Component, HostListener, TemplateRef, ViewChild } from '@angular/core';
import { AuthService, BrokerUserInfo } from '../services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { FlatfileField, FlatFileService } from '../services/flat-file.service';
import { fieldToDate, fieldToNumberWithDecimals } from '../utilities/flatFileHooks';
import FlatfileImporter from 'flatfile-csv-importer';
import { LoadService } from '../../modules/loads/load.service';
import { shareReplay } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { shareReplayComponentConfig, unitsForFlatFile } from '../constants';
import { LoadSearchComponent } from '../../modules/vst/vst-loads/load-search/load-search.component';
import { ShipmentExportComponent } from '../components/shipment-export/shipment-export.component';
import * as LogRocket from 'logrocket';

const fields: FlatfileField[] = [
  {
    label: 'Customer',
    key: 'customerName',
    alternates: ['Shipper'],
    validators: [],
  },
  {
    key: 'externalIdentifier',
    label: 'External Identifier',
    alternates: ['Reference Number'],
  },
  {
    label: 'Shipping Comments',
    key: 'shippingComments',
    alternates: ['Comments'],
    validators: [],
  },
  {
    label: 'Shipper External Identifier',
    key: 'shipperExternalIdentifier',
    alternates: ['Shipper'],
    validators: [],
  },
  {
    label: 'Equipment Type',
    key: 'equipmentType',
    alternates: ['Trailer', 'Equipment'],
    validators: [
      {
        validate: 'required',
        error: 'Equipment is required',
      },
    ],
    type: 'select',
    options: [
      {
        value: 'dry_van',
        label: 'Dry Van',
      },
      {
        value: 'flatbed',
        label: 'Flatbed',
      },
      {
        value: 'reefer',
        label: 'Reefer',
      },
    ],
  },
  {
    label: 'Force Update',
    key: 'forceUpdate',
    alternates: [],
    type: 'checkbox',
    validators: [
      {
        validate: 'required',
        error: 'Use Force Update is required',
      },
    ],
  },
  {
    label: 'Use FCFS',
    key: 'useFCFS',
    alternates: ['FCFS'],
    type: 'checkbox',
    validators: [
      {
        validate: 'required',
        error: 'Use FCFS is required',
      },
    ],
  },
  {
    label: 'Pickup Facility Name',
    key: 'pickupFacilityName',
    alternates: [],
    validators: [
      {
        validate: 'required',
        error: 'Pickup Facility Name is required',
      },
    ],
  },
  {
    label: 'Pickup Window Starts At',
    key: 'pickupWindowStartsAt',
    alternates: ['Pickup Starts'],
    validators: [
      {
        validate: 'required',
        error: 'Pickup Window Starts At is required',
      },
    ],
  },
  {
    label: 'Pickup Window Ends At',
    key: 'pickupWindowEndsAt',
    alternates: ['Pickup Ends'],
    validators: [
      {
        validate: 'required',
        error: 'Pickup Window Ends At is required',
      },
    ],
  },
  {
    label: 'Pickup Instructions',
    key: 'pickupDetails',
    alternates: ['Pickup Comments', 'Pickup Details'],
    validators: [],
  },
  {
    label: 'Pickup Reference Number',
    key: 'pickupReferenceNumber',
    alternates: [],
    validators: [],
  },
  {
    label: 'Pickup Appointment Number',
    key: 'pickupAppointmentTrackingNumber',
    alternates: [],
    validators: [],
  },
  {
    label: 'Dropoff Facility Name',
    key: 'dropoffFacilityName',
    alternates: [],
    validators: [
      {
        validate: 'required',
        error: 'Dropoff Facility Name is required',
      },
    ],
  },
  {
    label: 'Dropoff Window Starts At',
    key: 'dropoffWindowStartsAt',
    alternates: ['Dropoff Starts'],
    validators: [
      {
        validate: 'required',
        error: 'Dropoff Window Starts At is required',
      },
    ],
  },
  {
    label: 'Dropoff Window Ends At',
    key: 'dropoffWindowEndsAt',
    alternates: ['Dropoff Ends'],
    validators: [
      {
        validate: 'required',
        error: 'Dropoff Window Ends At is required',
      },
    ],
  },
  {
    label: 'Dropoff Instructions',
    key: 'dropoffDetails',
    alternates: ['Dropoff Comments'],
    validators: [],
  },
  {
    label: 'Dropoff Reference Number',
    key: 'dropoffReferenceNumber',
    alternates: [],
    validators: [],
  },
  {
    label: 'Dropoff Appointment Number',
    key: 'dropoffAppointmentTrackingNumber',
    alternates: [],
    validators: [],
  },
  {
    label: 'Rate',
    key: 'rate',
    alternates: [],
    validators: [
      {
        validate: 'required',
        error: 'Rate is required',
      },
    ],
  },
  {
    label: 'Rate On File',
    key: 'rateOnFile',
    alternates: [],
    validators: [],
  },
  {
    label: 'Cargo Value',
    key: 'cargoValue',
    alternates: ['Value of Cargo'],
    validators: [
      {
        validate: 'required',
        error: 'Cargo Value is required',
      },
    ],
  },
  {
    label: 'Weight Pounds',
    key: 'weightPounds',
    alternates: ['Weight'],
    validators: [
      {
        validate: 'required',
        error: 'Weight Pounds is required',
      },
    ],
  },
  {
    label: 'Commodity',
    key: 'commodity',
    alternates: ['Payload'],
    validators: [
      {
        validate: 'required',
        error: 'Equipment is required',
      },
    ],
  },
  {
    label: 'Quantity',
    key: 'quantity',
    alternates: [],
    validators: [
      {
        validate: 'required',
        error: 'Quantity is required',
      },
    ],
  },
  {
    label: 'Unit',
    key: 'unit',
    alternates: [],
    validators: [
      {
        validate: 'required',
        error: 'Unit is required',
      },
    ],
    type: 'select',
    options: unitsForFlatFile,
  },
];

@Component({
  selector: 'tb-side-nav',
  templateUrl: './side-nav.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavComponent {
  @ViewChild('uploadResult') public uploadResultTemplate: TemplateRef<any>;
  public userInfo$: Observable<BrokerUserInfo>;
  public importer: FlatfileImporter;
  private successfullyUploaded$$ = new BehaviorSubject<string[]>([]);
  public successfullyUploaded$: Observable<string[]> = this.successfullyUploaded$$.pipe(
    shareReplay(shareReplayComponentConfig),
  );
  private failedToUpload$$ = new BehaviorSubject<[string, string][]>([]);
  public failedToUpload$: Observable<[string, string][]> = this.failedToUpload$$.pipe(
    shareReplay(shareReplayComponentConfig),
  );

  constructor(
    private authService: AuthService,
    private flatFile: FlatFileService,
    private loadService: LoadService,
    private matDialog: MatDialog,
  ) {
    this.userInfo$ = this.authService.userInfo$;
    this.setupImporter();
  }

  public logout() {
    this.authService.logout();
  }

  private async setupImporter() {
    this.importer = await this.flatFile.getImporter(fields, 'Agent Shipments');
    this.importer.registerFieldHook('breakevenRate', fieldToNumberWithDecimals);
    this.importer.registerFieldHook('targetMarginRate', fieldToNumberWithDecimals);
    this.importer.registerFieldHook('cargoValue', fieldToNumberWithDecimals);
    this.importer.registerFieldHook('rate', fieldToNumberWithDecimals);
    this.importer.registerFieldHook('rateOnFile', fieldToNumberWithDecimals);
    this.importer.registerFieldHook('weightPounds', fieldToNumberWithDecimals);
    this.importer.registerFieldHook('tempFahrenheit', fieldToNumberWithDecimals);
    this.importer.registerFieldHook('detentionNotificationMinutes', fieldToNumberWithDecimals);
    this.importer.registerFieldHook('listingInvalidAt', fieldToDate);
    this.importer.registerFieldHook('pickupWindowStartsAt', fieldToDate);
    this.importer.registerFieldHook('pickupWindowEndsAt', fieldToDate);
    this.importer.registerFieldHook('dropoffWindowStartsAt', fieldToDate);
    this.importer.registerFieldHook('dropoffWindowEndsAt', fieldToDate);
    this.importer.registerFieldHook('quantity', fieldToNumberWithDecimals);
  }

  @HostListener('document:keydown.control.enter', ['$event'])
  public openLoadSearchEnter($event: KeyboardEvent) {
    $event.preventDefault();
    this.openLoadSearch();
  }

  @HostListener('document:keydown.control.k', ['$event'])
  public openLoadSearchK($event: KeyboardEvent) {
    $event.preventDefault();
    this.openLoadSearch();
  }

  public openLoadSearch() {
    if (LogRocket) {
      LogRocket.track('searchOpened');
    }
    this.matDialog.open(LoadSearchComponent, {
      panelClass: ['w-full', 'md:w-1/2', 'lg:w-1/3', 'no-padding-dialog'],
    });
  }

  public downloadShipmentExport() {
    this.matDialog.open(ShipmentExportComponent);
  }
}
