import { Pipe, PipeTransform } from '@angular/core';
import { LAMStatus } from '../types';

const statusMap: Record<LAMStatus, string> = {
  [LAMStatus.readyForUse]: 'Ready for Use',
  [LAMStatus.notAvailable]: 'Reserved',
  [LAMStatus.maintenanceNeeded]: 'Needs Maintenance',
  [LAMStatus.inspectionNeeded]: 'Needs Inspection',
  [LAMStatus.inCustody]: 'In Custody',
};

@Pipe({
  name: 'trailerStatus',
})
export class TrailerStatusPipe implements PipeTransform {
  public transform(value: LAMStatus): string {
    return statusMap[value] || value;
  }
}
