<div mat-dialog-content>
  <div class="flex flex-col gap-y-2 p-2 py-3 overflow-y-auto md:max-h-[400px]">
    <mat-form-field class="flex-1">
      <mat-icon matPrefix>search</mat-icon>
      <mat-label>Start typing to search for loads</mat-label>
      <input #searchInput matInput autocomplete="off" [formControl]="searchBox" />
      <mat-progress-spinner *ngIf="active$ | async" matSuffix mode="indeterminate" diameter="20"></mat-progress-spinner>
    </mat-form-field>
    <ng-container
      *ngIf="{
        loads: maxLoadSearchResults$ | async
      } as searchResults"
    >
      <div class="flex flex-col gap-y-1" *ngIf="searchResults.loads?.results?.length">
        <div class="flex flex-row justify-end">
          <div><button mat-button color="primary" (click)="openAll()">Open All</button></div>
        </div>
        <a
          [routerLink]="['/vst/loads', load.id]"
          (click)="ref.close(load.id)"
          *ngFor="let load of searchResults.loads?.results; trackBy: searchResults.loads?.results | trackByKey : 'id'"
          class="flex flex-row cursor-pointer justify-between p-2 hover:dark:bg-zinc-700 hover:bg-gray-200 color-transition px-2 py-1 result"
        >
          <div class="max-w-2/3">
            <div class="font-semibold">{{ load.referenceNumber || load.id }}</div>
            <div
              class="bg-blue-500 text-sm text-white font-medium max-w-min px-1 rounded-sm my-1"
              *ngIf="(firstResult$ | async) === load"
            >
              Enter
            </div>
          </div>
          <div class="text-right">
            <div>
              {{ load.status | lohiLoadStatus }}
            </div>
            <div *ngIf="load.assetName">
              <div class="text-xs text-gray-600">Trailer</div>
              {{ load.assetName }}
            </div>
          </div>
        </a>
        <div>
          <button color="accent" mat-button *ngIf="searchResults.loads?.showMore" (click)="showMoreLoads()">
            Show More
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
