import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { BehaviorSubject, interval, timer } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMapTo } from 'rxjs/operators';
import { addSeconds, format, isPast, startOfSecond } from 'date-fns';
import { preciseDiffToHuman } from '../../pipes/from-now.pipe';
import { shareReplayComponentConfig } from '../../constants';

@Component({
  selector: 'tb-ag-grid-date-countdown',
  templateUrl: './ag-grid-date-countdown.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgGridDateCountdownComponent implements ICellRendererAngularComp {
  private cellValue$$ = new BehaviorSubject<string>(null);
  public cellDisplay$ = this.cellValue$$.asObservable().pipe(
    map((value) => {
      if (!value) {
        return null;
      }
      if (value.length === 10) {
        return format(new Date(value), 'MMM d yyyy');
      }
      return format(new Date(value), 'MMM d yyyy h:mm a');
    }),
    shareReplay(shareReplayComponentConfig),
  );
  public countdown$ = timer(addSeconds(startOfSecond(new Date()), 1), 1000).pipe(
    map(() => {
      const value = this.cellValue$$.value;
      if (!value) {
        return null;
      }
      const date = new Date(value);
      if (isPast(date)) {
        return null;
      }
      return preciseDiffToHuman(date, 1, '', true, false);
    }),
    map((value) => {
      if (!value || value === '—') {
        return null;
      }
      return value.trim();
    }),
    distinctUntilChanged(),
  );

  public agInit(params: ICellRendererParams): void {
    this.cellValue$$.next(params.value);
  }

  public refresh(params: ICellRendererParams): boolean {
    this.cellValue$$.next(params.value);
    return true;
  }
}
