import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SnackBarService } from './snackbar.service';
import { NGXLogger } from 'ngx-logger';

export const NO_ERROR_LOGGING = new HttpContextToken<boolean>(() => false);

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptorService {
  constructor(
    private authService: AuthService,
    private afAuth: AngularFireAuth,
    private snackbar: SnackBarService,
    private logger: NGXLogger,
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.authService.isLoggedIn$.pipe(
      filter((isLoggedIn) => !!isLoggedIn),
      switchMap(() => this.afAuth.idToken),
      take(1),
      switchMap((token) => {
        const setHeaders = {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${token}`,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          'Content-Type': req.headers.get('Content-Type') || 'application/json',
          'web-timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
          'web-timezone-offset-minutes': `${new Date().getTimezoneOffset()}`,
          'x-api-web-app': 'broker',
        };
        let ignoreContentType =
          typeof req.body === 'undefined' ||
          req.body === null ||
          req.body.toString() === '[object FormData]' ||
          req.headers.has('Content-Type');
        if (ignoreContentType) {
          delete setHeaders['Content-Type'];
        }
        const copy = req.clone({
          setHeaders,
        });
        return next.handle(copy).pipe(
          catchError((err) => {
            let showSnackbar = true;
            if (req.context.get(NO_ERROR_LOGGING)) {
              showSnackbar = false;
            }
            if (localStorage.getItem('NO_ERROR_LOGGING') === 'true') {
              showSnackbar = false;
            }
            this.logError(err, showSnackbar);
            return throwError(err);
          }),
        );
      }),
    );
  }

  private logError(error: any, showSnackbar: boolean = true) {
    this.logger.error(error);
    if (error instanceof HttpErrorResponse && showSnackbar) {
      if (error.error.isClientError) {
        this.snackbar.showError(error.error.messageToUser);
      } else {
        this.snackbar.showError('Unexpected Error Occurred Processing Request');
      }
    }
  }
}
